import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthService } from '@qaroni-app/auth/services/o-auth.service';
import { GuardOAuthSnackbars } from '../snackbars/guard-o-auth-snackbars.config';

const redirectTo: string[] = ['/'];

export const OauthGuardCanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const router = inject(Router);
  const oAuthService = inject(OAuthService);
  const snackbar = inject(MatSnackBar);

  if (oAuthService.hasOAuth) {
    return true;
  }
  snackbar.open(
    GuardOAuthSnackbars.failOAuth.message,
    GuardOAuthSnackbars.failOAuth.closeBtn,
    GuardOAuthSnackbars.failOAuth.config
  );
  router.navigate(redirectTo);
  return false;
};

export const OauthGuardCanLoadFn = (): boolean => {
  const router = inject(Router);
  const oAuthService = inject(OAuthService);
  const snackbar = inject(MatSnackBar);
  if (oAuthService.hasOAuth) {
    return true;
  }
  snackbar.open(
    GuardOAuthSnackbars.failOAuth.message,
    GuardOAuthSnackbars.failOAuth.closeBtn,
    GuardOAuthSnackbars.failOAuth.config
  );
  router.navigate(redirectTo);
  return false;
};
